import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import projectsData from '../data/projects.json'
import ProjectCard from '../components/projects/ProjectCard'
import {Grid} from "@mui/material";

const SecondPage = () => (
  <Layout>
        <Grid container spacing={2} direction="column" justifyContent="space-between">
                <Grid item>
                        <Seo title="Projects" />
                </Grid>
                <Grid item>
                        <h1>Projects</h1>
                </Grid>
                <Grid item>
                        <p>These are some of the projects that I was either lead developer on, or created fully myself.</p>
                </Grid>
        </Grid>
        <Grid container spacing={4}>
          {projectsData.map( project =>
              <Grid item sm={6} md={6} lg={6}>
                <ProjectCard project={project} />
              </Grid>
              )}
        </Grid>
  </Layout>
)

export default SecondPage
